import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { MdCameraEnhance, MdDesktopMac, MdMessage, MdSettings, MdHeadsetMic, MdInsertChart, MdErrorOutline, MdMoveToInbox } from 'react-icons/md';
import { FaVials, FaVial, FaYoutube, FaBox, FaStore } from 'react-icons/fa';
import { Badge } from 'reactstrap';
import { openLink, isWindows } from 'libs/Utils';
import { withRouter } from 'react-router-dom';

import axios from 'axios';

import './Menu.scss';

@inject('locale', 'auth', 'screen')
@observer
class Menu extends Component {
  handleMenuClick = () => {
    if (getComputedStyle(document.querySelector('#content')).marginLeft === '0px') {
      this.props.onToggleMenu();
    }
  };

  handleOtpLink = async (e) => {
    e.preventDefault();
    const result = await axios.get(e.currentTarget.href);
    const url = `https://report.ntllab.com/webclient/sso.aspx?compcode=${this.props.auth.doctor.compCode}&otp=${result.data.message}`;
    openLink(url);
  };

  renderMenuItem = ({ to, icon: Icon, labelId, badge = null, external = false, onClick }) => (
    <li>
      {external ? (
        <a href={to} onClick={onClick}>
          <Icon /> <span><FormattedMessage id={labelId} /></span> {badge}
        </a>
      ) : (
        <NavLink exact to={to} activeClassName="active">
          <Icon /> <span><FormattedMessage id={labelId} /></span> {badge}
        </NavLink>
      )}
    </li>
  );

  render() {
    const { auth, screen, locale } = this.props;
    const isKorean = locale._locale === 'ko';
    const isNotVM = process.env.REACT_APP_SERVER_TYPE !== 'vm';

    const menuItems = [
      auth.doctor.useCervicoYN && { to: '/request', icon: MdCameraEnhance, labelId: 'menu.request' },
      auth.doctor.useCervicoYN && auth.doctor.useAI && { to: '/cerviai', icon: MdMoveToInbox, labelId: 'menu.cerviai' },
      auth.doctor.useCervicoYN && auth.doctor.showResult && { to: '/result', icon: MdDesktopMac, labelId: 'menu.response' },
      auth.doctor.otherTestYN && isNotVM && isKorean && {
        to: '/api/rq/other-test/otp',
        icon: FaVials,
        labelId: 'menu.other',
        external: true,
        onClick: this.handleOtpLink,
      },
      isNotVM && isKorean && { to: '/other-test', icon: FaVial, labelId: 'menu.other-test' },
      isNotVM && isKorean && { to: '/sms-result', icon: MdMessage, labelId: 'menu.sms' },
      auth.doctor.useYZoneYN && isNotVM && isKorean && { to: '/yzone', icon: MdMessage, labelId: 'menu.yzone' },
      isNotVM && { to: '/report', icon: MdInsertChart, labelId: 'menu.report' },
      auth.doctor.useCervicoYN && isNotVM && isWindows() && isKorean && { to: '/support', icon: MdHeadsetMic, labelId: 'menu.remoteAssist' },
      window.rq && { to: '/setting', icon: MdSettings, labelId: 'menu.setting' },
      auth.doctor.useCervicoYN && isNotVM && isKorean && {
        to: '/notice',
        icon: MdErrorOutline,
        labelId: 'menu.notice',
        badge: screen.unreadNoticeCount > 0 && (
          <span className="badge badge-pill badge-pink">{screen.unreadNoticeCount}</span>
        ),
      },
      auth.doctor.useCervicoYN && isNotVM && isKorean && {
        to: '/video',
        icon: FaYoutube,
        labelId: 'menu.video',
        badge: screen.unreadVideoCount > 0 && (
          <span className="badge badge-pill badge-pink">{screen.unreadVideoCount}</span>
        ),
      },
      auth.doctor.useCervicoYN && auth.doctor.useCerviboxYN && isNotVM && isKorean && {
        to: '/cervibox',
        icon: FaBox,
        labelId: 'menu.cervibox',
        badge: <Badge color="info"><FormattedMessage id="field.beta" /></Badge>,
      },
      auth.doctor.useCervicoYN && auth.doctor.useCervitemYn && isNotVM && isKorean && {
        to: '/cervitem-main',
        icon: FaStore,
        labelId: 'menu.cervitem',
        badge: <Badge color="info"><FormattedMessage id="field.beta" /></Badge>,
      },
    ].filter(Boolean);

    return (
      <nav id="menu">
        <ul onClick={this.handleMenuClick}>
          {menuItems.map((item, index) => this.renderMenuItem(item))}
        </ul>
      </nav>
    );
  }
}

export default withRouter(Menu);
